import axios from 'axios';

const httpClient = axios.create({
	baseURL: window.context.recommUrl
});

export default {
	getRecommendations(portalId) {
		return httpClient.get('', {
			headers: {
				'X-Request-ID': window.crypto.randomUUID(),
				'X-Log-Parameters': '{"channel": "PORTAL"}',
				...(portalId && { portalId })
			}
		});
	}
};
