import Vue, { getCurrentInstance, nextTick, watch } from 'vue';
import { computed, ref } from 'vue';
import personalizationApi from '../../../api/personalization-api';
import { COOKIE_PORTAL_ID_NAME } from '../constants/constants';

const PERSONALIZATION_STATE = {
	INIT: 'INIT',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR'
};

const STATUSES = {
	HIDDEN: 'hidden',
	ERROR: 'error'
};

const fragments = { ...window.context.defaultFragments, ...window.context.personalizedFragments };

const personalizationState = ref(PERSONALIZATION_STATE.INIT);
const isSuccess = computed(() => personalizationState.value == PERSONALIZATION_STATE.SUCCESS);
const isError = computed(() => personalizationState.value == PERSONALIZATION_STATE.ERROR);
const portalId = Vue.cookie.get(COOKIE_PORTAL_ID_NAME);
const recommData = ref(null);

const getDataAttributes = (moduleId) => {
	const attributes = recommData.value[moduleId].map((data, index) => ({
		'data-item_id': data.recommId,
		'data-item_model': data.model,
		'data-item_position': index,
		'data-item_module': moduleId
	}));
	return attributes;
};

const initPersonalization = async () => {
	try {
		if (window.context.hasPersonalization) {
			const { data } = await personalizationApi.getRecommendations(portalId);
			recommData.value = data;
		}
		personalizationState.value = PERSONALIZATION_STATE.SUCCESS;
	} catch {
		personalizationState.value = PERSONALIZATION_STATE.ERROR;
	}
};

export const useMherPersonalization = (params) => {
	const mherFragment = ref(null);
	const needsMherPlaceholder = ref(true);
	const dataAttributes = ref(null);

	const setPersonalizedMher = () => {
		try {
			const mher = recommData.value[params.moduleId][0];
			mherFragment.value = fragments[mher.recommId].mher;
			dataAttributes.value = getDataAttributes(params.moduleId)[0];
			if (!mherFragment.value) {
				setDefaultMher();
			} else {
				hideMherPlaceholder();
			}
		} catch {
			setDefaultMher();
		}
	};

	const setDefaultMher = () => {
		mherFragment.value = fragments[params.defaultId].mher;
		if (mherFragment.value) {
			hideMherPlaceholder();
		}
	};

	const hideMherPlaceholder = () => {
		nextTick(() => {
			needsMherPlaceholder.value = false;
			document.querySelector('#mher-placeholder').style.display = 'none';
		});
	};

	watch(
		personalizationState,
		() => {
			if (isSuccess.value && params.personalized) {
				setPersonalizedMher();
			} else if (isError.value || !params.personalized) {
				setDefaultMher();
			}
		},
		{ immediate: true }
	);

	return {
		mherFragment,
		needsMherPlaceholder,
		dataAttributes
	};
};

export const useMhapPersonalization = (params) => {
	const mhapFragments = ref(null);
	const needsMhapPlaceholder = ref(true);
	const dataAttributes = ref([null, null, null]);

	const mhap = ref([null, null, null]);

	const setPersonalizedMhap = () => {
		try {
			mhap.value = recommData.value[params.moduleId];
			mhapFragments.value = mhap.value.map((_fragment, index) => fragments[mhap.value[index].recommId].mhap);
			dataAttributes.value = getDataAttributes(params.moduleId);
			if (!mhapFragments.value) {
				setDefaultMhap();
			} else {
				hideMhapPlaceholder();
			}
		} catch {
			setDefaultMhap();
		}
	};

	const setDefaultMhap = () => {
		mhapFragments.value = mhap.value.map((_fragment, index) => fragments[params.defaultIds[index]].mhap);
		if (mhapFragments.value) {
			hideMhapPlaceholder();
		}
	};

	const hideMhapPlaceholder = () => {
		nextTick(() => {
			needsMhapPlaceholder.value = false;
			document.querySelector('#mhap-placeholder').style.display = 'none';
		});
	};

	watch(
		personalizationState,
		() => {
			if (isSuccess.value && params.personalized) {
				setPersonalizedMhap();
			} else if (isError.value || !params.personalized) {
				setDefaultMhap();
			}
		},
		{ immediate: true }
	);

	return {
		mhapFragments,
		needsMhapPlaceholder,
		dataAttributes
	};
};

export const useMasdPersonalization = (params) => {
	const vm = getCurrentInstance().proxy;

	const masdFragments = ref(null);
	const needsMasdPlaceholder = ref(true);
	const dataAttributes = ref(null);

	const setPersonalizedMasd = () => {
		try {
			const masd = recommData.value[params.moduleId];
			if (masd?.visibility == STATUSES.HIDDEN) {
				sendGtm(STATUSES.HIDDEN);
				needsMasdPlaceholder.value = false;
				return;
			}
			masdFragments.value = masd.map((masdIds) => fragments[masdIds.recommId].masd);
			dataAttributes.value = getDataAttributes(params.moduleId);
			if (!masdFragments.value) {
				setDefaultMasd();
			} else {
				needsMasdPlaceholder.value = false;
			}
		} catch {
			sendGtm(STATUSES.ERROR);
			setDefaultMasd();
		}
	};

	const setDefaultMasd = () => {
		try {
			masdFragments.value = [fragments[params.defaultId].masd];
		} catch {
			console.warn('defaultId for masd module is not available');
		} finally {
			needsMasdPlaceholder.value = false;
		}
	};

	const sendGtm = (status) => {
		vm.$gtm.trackEvent({
			event: 'recom_failed',
			recom_status: status,
			modul: params.moduleId
		});
	};

	watch(
		personalizationState,
		() => {
			if (isSuccess.value && params.personalized) {
				setPersonalizedMasd();
			} else if (isError.value || !params.personalized) {
				setDefaultMasd();
			}
		},
		{ immediate: true }
	);

	return {
		masdFragments,
		needsMasdPlaceholder,
		dataAttributes
	};
};

export const usePersonalization = () => {
	initPersonalization();
};
