import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import messages from './mtes-lang.json';
export default {
	i18n: {
		messages
	},
	data() {
		const vm = this;
		return {
			slidesPerView: 0,
			slidesLength: 0,
			swiperOptions: {
				a11y: {
					paginationBulletMessage: this.$t('jumpToPage')
				},
				slidesPerView: 1,
				pagination: {
					el: '.mtes__pagination',
					clickable: true
				},
				on: {
					breakpoint({ slidesPerView }) {
						vm.slidesPerView = slidesPerView;
					},
					init() {
						vm.slidesLength = this.slides.length;
					}
				}
			}
		};
	},
	components: {
		Swiper,
		SwiperSlide
	},
	computed: {
		hasPager() {
			return this.slidesLength > this.slidesPerView;
		}
	}
};
