export default {
	data: () => ({
		isVisible: true
	}),
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		isTablet() {
			return this.$resize && this.$mq.above(768);
		}
	},
	render() {
		return this.$scopedSlots.default({
			isMobile: this.isMobile,
			isTablet: this.isTablet
		});
	}
};
