import tabs from '../../common/components/tabs/tabs.vue';
import videoPreview from '../../common/components/video-preview/video-preview.vue';
import videoPlayer from '../../common/components/video-player/video-player.vue';
import mevp from '../MEVP/mevp';

export default {
	extends: tabs,
	data() {
		return {
			videoIndex: null
		};
	},
	props: {
		modalId: {
			required: true
		}
	},
	methods: {
		setVideoIndex(index) {
			this.videoIndex = index;
		}
	},
	watch: {
		activeTab() {
			this.$nextTick(() => {
				let elem = this.$refs.tablist.querySelector('li.mfch-tab-list__item.mfch-tab-list__item--active');
				let scrollableElem = document.querySelector('.mfch-tab');
				let scrollTo = this.isElementOutViewport(elem, scrollableElem);
				this.$refs.tabContainer.scrollLeft -= scrollTo.left;
				this.$refs.tabContainer.scrollLeft += scrollTo.right;
			});
		}
	},
	components: {
		videoPreview,
		videoPlayer,
		mevp
	}
};
