export default {
	data() {
		return {
			cardId: null,
			showPrecalculation: false
		};
	},
	created() {
		this.$on('open-precalculation', (cardId) => {
			this.showPrecalculation = true;
			this.cardId = cardId;

			this.$nextTick(() => {
				let elem = document.querySelector('#precalculation');
				this.$scrollTo(elem, { duration: 500 });
			});
		});
	}
};
