import VueTab from '@ui-common/vue-tab';
import getQueryParam from 'get-query-param';
export default {
	props: {
		id: {
			type: String,
			required: false
		}
	},
	components: {
		VueTab
	},
	computed: {
		urlTabId() {
			return parseInt(getQueryParam(`${this.id}-tab`, window.location.href), 10) - 1 || 0;
		}
	},
	data() {
		return {
			tabRef: this.$refs.tabRef
		};
	},
	mounted() {
		this.tabRef = this.$refs.tabRef;
	}
};
