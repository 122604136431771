import exchangeApi from '../../../api/exchange-api';
let exchangeMarzs;
let callDispatched;
const getExchangeMarzs = async () => {
	try {
		if (!callDispatched) {
			const response = await exchangeApi.getEcbDifference();
			if (response && response.data && response.data.ecbDifference) {
				exchangeMarzs = response.data.ecbDifference;
			}
		}
		callDispatched = true;
		if (!exchangeMarzs && callDispatched) {
			setTimeout(() => {
				getExchangeMarzs();
			}, 500);
		}
	} catch (error) {
		console.error(error);
	}
	return exchangeMarzs;
};

export default getExchangeMarzs;
