import { VueAccordion, VueAccordionItem } from '@ui-common/vue-accordion';
export default {
	data: () => ({
		isOpen: null
	}),
	props: {
		autoScroll: {
			default: false
		},
		opened: {
			required: false
		},
		inverse: {
			required: false
		}
	},
	components: {
		VueAccordion,
		VueAccordionItem
	},
	mounted() {
		this.isOpen = this.opened;
		if (this.autoScroll) {
			this.$scrollTo(this.$el);
		}
	}
};
