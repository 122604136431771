import { ref } from 'vue';
import donationApi from '../../../../api/donation-api';
import { isEmpty } from 'ramda';

const donationIds = window.context.donationIds;
const donationSummaries = ref({});
const requestSent = ref(false);

const fetchSummaries = async () => {
	const { data } = await donationApi.getDonations(donationIds);
	donationSummaries.value = data;
};
const summaryExists = (donationId) => {
	return donationSummaries.value[donationId] !== undefined;
};
const getDonationAmount = (donationId) => {
	return donationSummaries.value[donationId]?.summary;
};
const getDonationDate = (donationId) => {
	return donationSummaries.value[donationId]?.lastUpdated;
};

export const useDonationData = () => {
	if (isEmpty(donationSummaries.value) && !requestSent.value) {
		requestSent.value = true;
		fetchSummaries();
	}
	return {
		fetchSummaries,
		summaryExists,
		getDonationAmount,
		getDonationDate
	};
};
