import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import messages from './mslc-lang.json';
export default {
	i18n: {
		messages
	},
	data() {
		const vm = this;
		return {
			slidesPerView: 0,
			slidesLength: 0,
			swiperOptions: {
				a11y: {
					prevSlideMessage: this.$t('previousPage'),
					nextSlideMessage: this.$t('nextPage'),
					firstSlideMessage: this.$t('firstPage'),
					lastSlideMessage: this.$t('lastPage'),
					paginationBulletMessage: this.$t('jumpToPage')
				},
				slidesPerView: 1,
				breakpoints: {
					768: {
						slidesPerView: 3,
						spaceBetween: 32
					}
				},
				pagination: {
					el: '.mslc__pagination',
					clickable: true
				},
				navigation: {
					nextEl: '.mslc__button-next',
					prevEl: '.mslc__button-prev'
				},
				on: {
					breakpoint({ slidesPerView }) {
						vm.slidesPerView = slidesPerView;
					},
					init() {
						vm.slidesLength = this.slides.length;
					}
				}
			}
		};
	},
	components: {
		Swiper,
		SwiperSlide
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		hasPager() {
			return this.slidesLength > this.slidesPerView;
		}
	}
};
