import Vue from 'vue';
import VueTouch from 'vue-touch';
Vue.use(VueTouch);
export default {
	data() {
		return {
			currentColumn: 0,
			cardWidth: null,
			deviceWidth: null
		};
	},
	props: {
		modalId: {
			required: false
		},
		openedTab: {
			required: false
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		isTablet() {
			return this.$resize && this.$mq.above(768) && this.$mq.below(959);
		},
		isDesktop() {
			return this.$resize && this.$mq.above(960) && this.$mq.below(1279);
		},
		isLargeDesktop() {
			return this.$resize && this.$mq.above(1280);
		}
	}
};
