import './layout.scss';
import Vue from 'vue';
import VueTableOfContent from '@ui-common/vue-table-of-content';
import macc from '../../modules/MACC/macc';
import mjbl from '../../modules/MJBL/mjbl.vue';
import meok from '../../modules/MEOK/meok';
import mevp from '../../modules/MEVP/mevp';
import mvtu from '../../modules/MVTU/mvtu';
import mtes from '../../modules/MTES/mtes';
import mtab from '../../modules/MTAB/mtab';
import mfch from '../../modules/MFCH/mfch';
import mstb from '../../modules/MSTB/mstb';
import msup from '../../modules/MSUP/msup';
import mltl from '../../modules/MLTL/mltl';
import mslc from '../../modules/MSLC/mslc';
import masd from '../../modules/MASD/masd.vue';
import VueCheckbox from '@ui-common/vue-checkbox';
import VueTab from '@ui-common/vue-tab';
import creditcardLanding from '../../applications/creditcard-landing/creditcard-landing.vue';
import AcceptPdfModal from '../../common/components/accept-pdf-modal/accept-pdf-modal';
import donation from '../../common/components/donation/donation.vue';
import videoPreview from '../../common/components/video-preview/video-preview.vue';
import videoPlayer from '../../common/components/video-player/video-player.vue';
import VueGallery from '@ui-common/vue-gallery';
import BranchAtmWidgetWrapper from '../../applications/branch-atm-widget-wrapper/branch-atm-widget-wrapper.vue';
import layoutBase from '../layout-base';
import sectionLayout from '../../common/elements/section-layouts/section-layout';

Vue.component('macc', macc);
Vue.component('mslc', mslc);
Vue.component('mjbl', mjbl);
Vue.component('meok', meok);
Vue.component('mevp', mevp);
Vue.component('mvtu', mvtu);
Vue.component('mtes', mtes);
Vue.component('mtab', mtab);
Vue.component('mfch', mfch);
Vue.component('mstb', mstb);
Vue.component('msup', msup);
Vue.component('mltl', mltl);
Vue.component('masd', masd);
Vue.component('vue-checkbox', VueCheckbox);
Vue.component('vue-tab', VueTab);
Vue.component('vue-table-of-content', VueTableOfContent);
Vue.component('accept-pdf-modal', AcceptPdfModal);
Vue.component('donation', donation);
Vue.component('video-player', videoPlayer);
Vue.component('video-preview', videoPreview);
Vue.component('vue-gallery', VueGallery);
Vue.component('section-tab', sectionLayout);
Vue.component('branch-atm-widget-wrapper', BranchAtmWidgetWrapper);
/* eslint-disable no-new*/
new Vue({
	el: '[data-layout-id=AV9LA2-app]',
	components: {
		creditcardLanding
	},
	extends: layoutBase
});
/* eslint-enable*/
