import Vue from 'vue';
import { VueAccordion, VueAccordionItem } from '@ui-common/vue-accordion';
Vue.component('VueAccordion', VueAccordion);
Vue.component('VueAccordionItem', VueAccordionItem);
import { mapState } from 'vuex';
export default {
	data() {
		return {};
	},
	computed: {
		...mapState('lahi', ['calculationLahiProductIdJsons'])
	}
};
