import axios from 'axios';
const httpClient = axios.create({
	baseURL: '/apps/donation'
});

export default {
	getDonations(donationIds) {
		const ids = donationIds?.length ? donationIds : [];
		return httpClient.get('/donation-summary', { params: { projectAliases: ids.join(',') } });
	}
};
