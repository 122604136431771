const formatAmount = (amount, options) => {
	const formatter = new Intl.NumberFormat('hu-HU', {
		style: 'currency',
		currency: 'HUF',
		maximumFractionDigits: 0,
		...options
	});
	return formatter.format(amount);
};

export const useCurrency = () => ({ formatAmount });
