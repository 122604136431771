const MEOK_TABLE_ROW_PATTERN = /meok-table-row-\d+/;
const MEOK_TABLE_ROW_PATTERN_GRAY = /meok-table-row-gray-\d+/;
const CONTAINER_SELECTORS = {
	TITLE_CONTAINER: '.meok-card-container-title',
	IMAGE_CONTAINER: '.meok-card-container-1',
	SUBTITLE_CONTAINER: '.meok-card-container-2',
	TEXT_CONTAINER: '.meok-card-container-3',
	EDU_CONTAINER: '.meok-card-container-4',
	LIST_CONTAINER: '.meok-card-container-5'
};
const MEOK_CARD_SELECTOR = '.meok-card';
const MEOK_CARD_SELECTOR_GRAY_BACKGROUND = '.meok__card-wrap';
export default {
	methods: {
		calculateAllCardsHeights(cardSelector) {
			for (let key in this.$refs) {
				if (this.isMeokTableRow(key)) {
					let cardsInOneRow = this.$refs[key].querySelectorAll(cardSelector);
					let numberOfCardsInOneRow = cardsInOneRow.length;
					if (numberOfCardsInOneRow > 1) {
						this.calculateCardHeights(cardsInOneRow);
					}
				}
			}
		},
		calculateCardHeights(cardsNodeList) {
			for (let containerSelector in CONTAINER_SELECTORS) {
				this.applyHighestValue(cardsNodeList, CONTAINER_SELECTORS[containerSelector], 'auto');
			}
			if (!this.isMobile) {
				for (let containerSelector in CONTAINER_SELECTORS) {
					let highestValue = this.getHighestValue(cardsNodeList, CONTAINER_SELECTORS[containerSelector]);
					this.applyHighestValue(cardsNodeList, CONTAINER_SELECTORS[containerSelector], highestValue);
				}
			}
		},
		applyHighestValue(cardsNodeList, containerSelector, value) {
			[...cardsNodeList].forEach((item) => {
				let elem = item.querySelector(containerSelector);
				if (elem) {
					elem.style.height = Number.isFinite(value) ? value + 'px' : value;
				}
			});
		},
		isMeokTableRow(refString) {
			return (
				this.$refs.hasOwnProperty(refString) &&
				this.$refs[refString] &&
				(MEOK_TABLE_ROW_PATTERN.test(refString) || MEOK_TABLE_ROW_PATTERN_GRAY.test(refString))
			);
		},
		getHighestValue(cardsNodeList, selector) {
			let heights = [...cardsNodeList].map((cardNode) => {
				let elem = cardNode.querySelector(selector);
				return elem ? this.getElementsHeight(elem) : 0;
			});
			return Math.max(...heights);
		},
		getElementsHeight(element) {
			let elementHeight = getComputedStyle(element).height;
			if (isNaN(parseInt(elementHeight, 10))) {
				return element.offsetHeight;
			} else {
				return parseInt(elementHeight, 10);
			}
		},
		calculateMeokCardsHeight() {
			this.calculateAllCardsHeights(MEOK_CARD_SELECTOR);
			this.calculateAllCardsHeights(MEOK_CARD_SELECTOR_GRAY_BACKGROUND);
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.calculateMeokCardsHeight();
		});
		window.addEventListener('resize', this.calculateMeokCardsHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calculateMeokCardsHeight);
	}
};
