import announcementApi from '../../../api/announcement-api';
const ANNOUNCEMENT_SET_ANNOUNCEMENTS = 'ANNOUNCEMENT_SET_ANNOUNCEMENTS';
const ANNOUNCEMENT_SET_TECHNICAL_ERROR = 'ANNOUNCEMENT_SET_TECHNICAL_ERROR';

const announcementState = {
	technicalError: false,
	announcements: []
};

const announcementActions = {
	async getAnnouncements({ commit }, params) {
		try {
			let { data } = await announcementApi.getAnnouncements(params);
			commit(ANNOUNCEMENT_SET_ANNOUNCEMENTS, data);
		} catch (err) {
			commit(ANNOUNCEMENT_SET_TECHNICAL_ERROR);
		}
	}
};

const announcementMutations = {
	[ANNOUNCEMENT_SET_ANNOUNCEMENTS](state, announcements) {
		state.announcements = announcements;
	},
	[ANNOUNCEMENT_SET_TECHNICAL_ERROR](state) {
		state.technicalError = true;
	}
};

export default {
	namespaced: true,
	state: announcementState,
	mutations: announcementMutations,
	actions: announcementActions
};
