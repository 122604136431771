import { VueAccordion, VueAccordionItem } from '@ui-common/vue-accordion';

export default {
	data() {
		return {
			isOpen: null
		};
	},
	props: ['opened', 'type', 'ga_category', 'ga_action', 'inverse'],
	components: {
		VueAccordion,
		VueAccordionItem
	},
	mounted() {
		this.isOpen = this.opened;
	}
};
