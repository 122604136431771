import VueFocus from 'vue-focus';
import getQueryParam from 'get-query-param';
export default {
	mixins: [VueFocus.mixin],
	data() {
		return {
			activeTab: null,
			selectedTab: null,
			focusedTab: null,
			selectedTabIndex: null
		};
	},
	props: {
		openedTab: {
			required: false
		},
		closeOnClick: {
			required: false,
			default: false
		},
		id: {
			type: String,
			required: false
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	methods: {
		changeTab(tabId) {
			if (this.closeOnClick && this.activeTab === 'tab' + tabId) {
				this.activeTab = this.selectedTab = null;
			} else {
				this.activeTab = this.selectedTab = 'tab' + tabId;
			}
			this.focusedTab = tabId;
			this.selectedTabIndex = tabId;
		},
		offset(el) {
			if (el) {
				let rect = el.getBoundingClientRect(),
					scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
					scrollTop = window.pageYOffset || document.documentElement.scrollTop;
				return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
			} else {
				return { top: 0, left: 0 };
			}
		},
		isElementOutViewport(el, scrollableElem) {
			let rect = el.getBoundingClientRect();
			let offsetLeft = scrollableElem.offsetLeft;
			return {
				left: rect.left < offsetLeft ? offsetLeft - rect.left : 0,
				right: rect.right > scrollableElem.clientWidth ? rect.right - scrollableElem.clientWidth : 0
			};
		}
	},
	mounted() {
		if (!isNaN(parseInt(this.openedTab, 10))) {
			this.activeTab = this.selectedTab = 'tab' + this.openedTab;
			this.selectedTabIndex = this.openedTab;
		} else {
			this.activeTab = this.selectedTab = null;
		}

		const urlTabId = getQueryParam(`${this.id}-tab`, window.location.href);
		if (typeof urlTabId != 'undefined' && !isNaN(parseInt(urlTabId, 10))) {
			this.activeTab = this.selectedTab = 'tab' + urlTabId;
			this.selectedTabIndex = urlTabId;
			this.$nextTick(() => {
				this.$refs.root.scrollIntoView();
			});
		}
	}
};
